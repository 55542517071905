<template>
  <CreateOrderForm store="order_auction" :multiProduct="false" withProduct />
</template>

<script>
import CreateOrderForm from "../../components/CreateOrderForm.vue";
export default {
  components: { CreateOrderForm },
};
</script>
